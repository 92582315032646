(function ($) {
  $('.webform').each(function () {
    $('input:radio[name="inscription[votreprofil]"]').change(function () {
      if ($(this).val() == 'Société') {
        $(".company").show();
        $(".private").hide();
      } else {
        $(".company").hide();
        $(".private").show();
      }
    });

    $('input:radio[name="inscription[assujettitva]"]').change(function () {
      if ($(this).val() == 'Oui') {
        $(".tva").show();
      } else {
        $(".tva").hide();
      }
    });

    $('input:radio[name="inscription[adresseproductionsimilaire]"]').change(function () {
      if ($(this).val() == 'Non') {
        $(".address-prod").show();
      } else {
        $(".address-prod").hide();
      }
    });

    $('input:radio[name="inscription[commentconnaitre]"]').change(function () {
      if ($(this).val() == 'Autre') {
        $(".commentconnaitreautre").show();
      } else {
        $(".commentconnaitreautre").hide();
      }
    });

    $("#inscription_tva").mask("BE 0999999999", { placeholder: " " });
    $("#inscription_payecompteiban").mask("BE99 9999 9999 9999", { placeholder: " " });
  });
})(jQuery);
