(function ($) {
  $('.smooth-scroll').each(function() {
    // Select all links with hashes
    $(this).on("click", function(event) {
      event.preventDefault();
      var target = $(this.hash);
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 1000);
    });
  });
})(jQuery);