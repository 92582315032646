(function ($) {
  $('.testimonials .swiper-container').each(function () {
    var that = $(this);

    new Swiper($(this)[0], {
      // loop: true,
      allowTouchMove: false,
      navigation: {
        nextEl: that.find('.swiper-button-next')[0],
        prevEl: that.find('.swiper-button-prev')[0],
      },
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false,
      // },
    });
  });
})(jQuery);
